import request from '@/utils/request'

// 直播--头部主播 热门主播 娱乐星知道
export function getLiveBanner(data) {
  return request({
    url: '/web/live/getLiveBanner',
    method: 'get',
    params: data
  })
}
// 直播--主播招募
export function liveJoin(data) {
  return request({
    url: '/web/live/liveJoin',
    method: 'POST',
    data
  })
}
